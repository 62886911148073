<template>
  <priori-modal ref="modal" title="Authenticator Apps" modal-id="compatible-apps-modal">
    <template #modal-trigger>
      <a href="" class="size-text-12px" @click.prevent="openModal">Which authenticator apps can I use?</a>
    </template>

    <div>
      <div class="bottom-20">
        Priori supports the following applications for authentication:
      </div>

      <ul class="bottom-20">
        <li>Google Authenticator</li>
        <li>Authy</li>
      </ul>

      <div>
        Both applications are available for download on iOS and Android app stores.
      </div>
    </div>
  </priori-modal>
</template>

<script>
import PrioriModal from 'vue-app/shared/components/priori-modal.vue';

export default {
  name: 'CompatibleAppsModal',

  components: {
    PrioriModal
  },

  methods: {
    openModal: function () {
      this.$refs.modal.openModal();
    }
  }
};
</script>

