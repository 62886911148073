import { render, staticRenderFns } from "./two-factor-authentication-settings.vue?vue&type=template&id=04138a60&scoped=true"
import script from "./two-factor-authentication-settings.vue?vue&type=script&lang=js"
export * from "./two-factor-authentication-settings.vue?vue&type=script&lang=js"
import style0 from "./two-factor-authentication-settings.vue?vue&type=style&index=0&id=04138a60&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04138a60",
  null
  
)

export default component.exports