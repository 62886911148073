<template>
  <priori-modal ref="modal" title="How Does Two-Factor Authentication Work?" modal-id="how-it-works-modal">
    <template #modal-trigger>
      <a href="" @click.prevent="openModal">How does two-factor authentication work?</a>
    </template>

    <div>
      <div class="bottom-20">
        Two-factor authentication will require a password and an authentication code every time you sign in.
      </div>

      <div class="bottom-20">
        After two-factor authentication is enabled, your sign-in experience will include the following steps:
      </div>

      <ul>
        <li>1. You will sign in with your password</li>
        <li>2. You will receive an authentication code</li>
        <li>3. You will enter this code during the sign-in process</li>
      </ul>
    </div>
  </priori-modal>
</template>

<script>
import PrioriModal from 'vue-app/shared/components/priori-modal.vue';

export default {
  name: 'HowItWorksModal',

  components: {
    PrioriModal
  },

  methods: {
    openModal() {
      this.$refs.modal.openModal();
    }
  }
};
</script>

<style lang="scss" scoped>
  ul {
    list-style: none;
    padding-left: 20px;
    margin-bottom: 0;

    li:not(:last-of-type) {
      margin-bottom: 8px;
    }
  }
</style>

