<template>
  <section class="uiv">
    <a href="" @click.prevent="open" v-if="buttonText">{{ buttonText }}</a>

    <modal v-model="modalOpen" size="md" :transition="0" :header="false" :footer="false" append-to-body>
      <div class="close-modal" @click="dismissModal()">
        <svg-icon name="x3" class="base-icon"></svg-icon>
      </div>

      <div class="notice-modal">
        <div class="notice-regular-text extra-padded">
          <div class="size-text-16px bottom-30">
            {{ primaryMsg }}
          </div>

          <div class="user-info bottom-30">
            <profile-photo :user="authenticatable" :small="true"></profile-photo>

            <div>
              <div class="bold-weight">
                {{ authenticatable.fullName }}
              </div>

              <div>
                {{ authenticatable.email }}
              </div>
            </div>
          </div>

          <validation-observer v-slot="{ handleSubmit }">
            <form role="form" class="a-form" method="POST" novalidate>
              <div class="row bottom-20">
                <div class="col-sm-10">
                  <validation-provider v-slot="{ errors }" mode="passive">
                    <label for="confirm-password">Password <span class="error-text" v-if="errors.length">{{ errors[0] }}</span></label>

                    <input ref="password" type="password" id="confirm-password" name="password" :class="['form-control', { 'has-error': errors.length }]" v-model="password" required>
                  </validation-provider>

                  <span class="error-text" v-if="showErrorMsg">Invalid credentials; please try again.</span>
                </div>
              </div>

              <button type="submit" class="nv-button-blue a-button-size right-10-not-xs" @click.prevent="handleSubmit(authenticate)">Confirm Password</button>

              <button type="button" class="nv-button-white a-button-size top-20-xs" @click="closeModal">Cancel</button>
            </form>
          </validation-observer>
        </div>
      </div>
    </modal>
  </section>
</template>

<script>
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import ProfilePhoto from 'vue-app/shared/components/profile-photo.vue';
import modalToggle from 'vue-app/shared/mixins/modal-toggle';
import interactionModes from 'vue-app/shared/mixins/interaction-modes';
import { ValidationObserver, ValidationProvider } from 'vee-validate';

export default {
  name: 'PasswordAuthenticationModal',

  components: {
    ProfilePhoto,
    SvgIcon,
    ValidationObserver,
    ValidationProvider
  },

  mixins: [
    interactionModes,
    modalToggle
  ],

  props: {
    authenticatable: {
      type: Object,
      required: true
    },

    passwordAuthentication: {
      type: Function,
      required: true
    },

    afterAuthenticationAction: {
      type: Function,
      default: null
    },

    buttonText: {
      type: String,
      default: undefined
    },

    initialMfaSetup: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      password: '',
      showErrorMsg: false,
      localAfterAuthenticationAction: null
    };
  },

  computed: {
    primaryMsg() {
      return this.initialMfaSetup ? 'You must complete the MFA setup process within five minutes of entering your password. Please re-enter your password to continue.' : 'Please re-enter your password to continue';
    }
  },

  watch: {
    modalOpen(isOpen) {
      if (isOpen) {
        this.password = '';
        this.showErrorMsg = false;
      }
    }
  },

  created() {
    this.localAfterAuthenticationAction = this.afterAuthenticationAction || this.defaultAfterAuthenticationAction;
  },

  methods: {
    authenticate() {
      this.passwordAuthentication(this.password)
      .then((response) => {
        this.localAfterAuthenticationAction(response);
        this.closeModal();
      })
      .catch(() => {
        this.showErrorMsg = true;
      });
    },

    open() {
      this.showErrorMsg = false;
      this.openModal();
      this.$nextTick().then(() => this.$refs.password.focus());
    },

    defaultAfterAuthenticationAction() {
      return;
    }
  }
};
</script>

<style lang="scss" scoped>
  .user-info {
    display: flex;
    column-gap: 15px;
  }

  .nv-button-blue, .nv-button-white {
    width: unset;
  }
</style>
