<template>
  <dropdown ref="dropdown" tag="div" :id="idAttr" class="k-dropdown" v-model="dropdownOpen">
    <button type="button" role="button" :id="`k-toggle-${idAttr}`" :class="['dropdown-toggle', { 'placeholder': !selectedOption }]">
      <span class="semibold-weight" v-if="selectedOption">{{ selectedOption.label }}</span>
      <span v-if="!selectedOption">Select One</span>
      <svg-icon name="caret-down" class="caret-down"></svg-icon>
      <svg-icon name="caret-up" class="caret-up"></svg-icon>
    </button>

    <template slot="dropdown" class="dropdown-menu" role="menu" :aria-labelledby="`k-toggle-${idAttr}`">
      <li role="menuitem" class="menu-item" v-for="option in options" :key="option.label" @click="selectOption(option)">
        <div class="dropdown-row-inner semibold-weight">
          {{ option.label }}
        </div>
      </li>
    </template>
  </dropdown>
</template>

<script>
export default {
  name: 'TrueFalseSelect',

  props: {
    value: Boolean,
    idAttr: String
  },

  data: function () {
    return {
      options: [
        { label: 'Yes', value: true },
        { label: 'No', value: false }
      ],
      dropdownOpen: false
    };
  },

  computed: {
    selectedOption: function () {
      return _.find(this.options, (option) => option.value === this.value);
    }
  },

  watch: {
    dropdownOpen: function () {
      if (!this.dropdownOpen) {
        this.$emit('blur');
      }
    }
  },

  methods: {
    selectOption(option) {
      this.$emit('input', option.value);
    }
  }
};
</script>
