<template>
  <div class="toggle-container">
    <button type="button" :id="id" :class="['toggle-button', 'with-legend', size, { 'toggle-on': value, 'no-text': !hasText }]" @click.prevent="disabled ? noop : $emit('click')" :disabled="disabled">{{ visibleText }}</button>
    <span v-if="title" :class="['bold-weight toggle-title size-text-15px', { 'pill-gray-text': !value }]">
      {{ title }}
    </span>
    <span v-if="subTitle" :class="['size-text-15px', { 'pill-gray-text': !value }]">
      {{ subTitle }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'ToggleButton',

  props: {
    value: {
      type: Boolean,
      required: true
    },

    id: {
      type: String,
      default: 'toggle-button'
    },

    disabled: {
      type: Boolean,
      default: false
    },

    hasText: {
      type: Boolean,
      default: true
    },

    trueText: {
      type: String,
      default: 'On'
    },

    falseText: {
      type: String,
      default: 'Off'
    },

    size: {
      type: String,
      default: 'lg'
    },

    title: {
      type: String
    },

    subTitle: {
      type: String
    }
  },

  computed: {
    visibleText() {
      if (!this.hasText) { return ''; }
      return this.value ? this.trueText : this.falseText;
    }
  }
};
</script>

<style lang="scss" scoped>
  .toggle-container {
    position: relative;
  }

  .toggle-button {
    &:disabled {
      opacity: .5;
      pointer-events: none;
    }
  }

  .toggle-title {
    padding-left: 10px;
  }
</style>
