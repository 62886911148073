<template>
  <edit-modal title="Change Password" :input="resource" v-model="resourceCopy" :on-save="save" ref="changePasswordModal">
    <template #modal-trigger>
      <password-authentication-modal
        :button-text="prompt"
        :password-authentication="passwordAuthentication"
        :authenticatable="resource"
        :after-authentication-action="openChangePasswordModal">
      </password-authentication-modal>
    </template>

    <template #default>
      <div class="row tight-columns">
        <div class="col-sm-6">
          <label for="new-user-password">Password</label>

          <validation-provider vid="password" v-slot="{ errors, failedRules, changed }" :bails="false" rules="required|oneNumber|min:15|oneUppercaseChar|oneLowercaseChar|oneSpecialChar">
            <input type="password" label="Password" name="password" id="new-user-password" class="form-control" placeholder="Password" autocomplete="off" v-model="resourceCopy.password">

            <div class="error-text top-5" v-if="errors.length">
              <span v-if="resourceCopy.password && resourceCopy.password.length">* Password doesn&rsquo;t meet requirements</span>

              <span v-else>{{ errors[0] }}</span>
            </div>

            <div class="top-15 size-text-12px dark-gray-text semibold-weight">
              <div class="bottom-5">
                Password must have:
              </div>

              <ul>
                <li v-for="(constraint, rule) in rules" :key="rule" class="bottom-5">
                  <svg-icon v-if="isValid(rule, failedRules) && changed" name="checkmark" class="base-icon success"></svg-icon>

                  <svg-icon v-else name="exclamation" class="base-icon"></svg-icon>
                  {{ constraint }}
                </li>
              </ul>
            </div>
          </validation-provider>
        </div>

        <div class="col-sm-6">
          <label for="password-confirmation">Confirm Password</label>

          <validation-provider vid="passwordConfirmation" v-slot="{ errors }" rules="required|confirmed:password">
            <input type="password" label="Password Confirmation" name="passwordConfirmation" id="password-confirmation" class="form-control" placeholder="Password Confirmation" autocomplete="off" v-model="resourceCopy.passwordConfirmation">

            <div class="error-text top-5" v-if="errors.length">
              {{ errors[0] }}
            </div>
          </validation-provider>
        </div>
      </div>
    </template>
  </edit-modal>
</template>

<script>
import EditModal from 'vue-app/shared/components/edit-modal.vue';
import PasswordAuthenticationModal from 'vue-app/shared/components/password-authentication-modal.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import { ValidationProvider } from 'vee-validate';

export default {
  name: 'ChangePasswordModal',

  components: {
    EditModal,
    PasswordAuthenticationModal,
    SvgIcon,
    ValidationProvider
  },

  props: {
    resource: {
      type: Object,
      required: true
    },

    prompt: {
      type: String,
      default: 'Change Password'
    },

    onSave: {
      type: Function,
      required: true
    },

    passwordAuthentication: {
      type: Function,
      required: true
    }
  },

  data: function () {
    return {
      resourceCopy: null,
      rules: {
        min: 'Minimum of 15 characters',
        oneNumber: 'At least one number',
        oneUppercaseChar: 'At least one uppercase character',
        oneLowercaseChar: 'At least one lowercase character',
        oneSpecialChar: 'At least one special character'
      }
    };
  },

  methods: {
    isValid: function (rule, failedRules) {
      return !Object.keys(failedRules).includes(rule);
    },

    openChangePasswordModal: function () {
      this.$refs.changePasswordModal.openModal();
    },

    save: function () {
      return this.onSave(this.resourceCopy);
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/scout/variables";

  ul {
    list-style: none;
    padding-inline-start: 0;

    li {
      display: flex;
      column-gap: 7px;

      .base-icon {
        fill: $k-dark-gray;
        width: 16px;

        &.success {
          fill: $k-green;
        }
      }
    }
  }
</style>
