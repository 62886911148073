<template>
  <div class="list-item">
    <div>
      <div class="bold-weight">
        {{ title }}
      </div>

      <div v-if="hasSubtitle" class="dark-gray-text top-5">
        {{ subtitle }}
      </div>

      <div v-if="hasAdditionalText" class="text-italic dark-gray-text top-10">
        {{ additionalText }}
      </div>
    </div>

    <div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ListItem',

  props: {
    title: {
      type: String,
      required: true
    },

    subtitle: {
      type: String,
      default: undefined
    },

    additionalText: {
      type: String,
      default: undefined
    }
  },

  computed: {
    hasSubtitle() {
      return this.subtitle && this.subtitle.length;
    },

    hasAdditionalText() {
      return this.additionalText && this.additionalText.length;
    }
  }
};
</script>

<style lang="scss" scoped>
  .list-item {
    display: flex;
    justify-content: space-between;
    column-gap: 30px;
    padding: 10px 0;
    line-height: 1.4;

    @media (min-width: 768px) {
      column-gap: 100px;
    }
  }
</style>
