<template>
  <div class="shadowed-box settings-box">
    <div class="box-header">
      <div class="row">
        <div class="col-sm-8 text-uppercase">
          {{ sectionTitle }}
        </div>

        <div v-if="!editMode && !firstTime" class="col-sm-4 size-text-13px normal-weight text-right-not-xs">
          <password-authentication-modal button-text="Edit" :password-authentication="passwordAuthentication" :after-authentication-action="toggleEditMode" :authenticatable="authenticatable"></password-authentication-modal>
        </div>
      </div>
    </div>

    <div v-if="firstTime && !editMode" class="box-content asymmetrical text-center">
      <div class="bottom-10 size-text-14px bold-weight">
        Two-factor authentication is not enabled yet
      </div>

      <div class="bottom-20 size-text-13px">
        Two-factor authentication adds a layer of security to your account by requiring more than one method to sign in (e.g. a password and an authentication code).
      </div>

      <div class="bottom-20 size-text-13px">
        <how-it-works-modal ref="howItWorksModal"></how-it-works-modal>
      </div>

      <div>
        <button type="button" class="nv-button-blue padded a-button-size" @click.prevent="setUpTwoFactor">Set up two-factor authentication</button>
      </div>

      <password-authentication-modal ref="setUpTwoFactor" :password-authentication="passwordAuthentication" :after-authentication-action="firstSetUp" :authenticatable="authenticatable"></password-authentication-modal>
    </div>

    <div v-if="!firstTime || editMode" class="box-content size-text-13px">
      <list-item title="Two-Factor Authentication" subtitle="Require a password and an authentication code every time you sign in." :additional-text="twoFactorAdditionalText">
        <div v-if="editMode">
          <toggle-button :value="mfaEnabled" @click="toggleMfa" :disabled="firstTime || (mfaEnabled && mandatoryMfa)"></toggle-button>
        </div>

        <div v-if="!editMode" :class="['semibold-weight', { 'green-text': mfaEnabled, 'dark-gray-text': !mfaEnabled }]">
          {{ mfaEnabled ? "On" : "Off" }}
        </div>
      </list-item>

      <hr v-if="mfaEnabled">

      <list-item v-if="mfaEnabled && !editMode && currentStrategy" title="Preferred Method" :subtitle="currentStrategy === 'email' ? authenticatable.email : ''">
        {{ strategyLabel }}
      </list-item>

      <div v-if="firstTime && mfaEnabled" class="bottom-20">
        <mfa-instructions :title="instructions.title" :content="instructions.content"></mfa-instructions>
      </div>

      <div v-if="mfaEnabled && editMode">
        <div v-if="firstTime" class="bold-weight bottom-10">
          Select a method of authentication:
        </div>

        <div v-if="!firstTime" class="bold-weight top-20 bottom-10">
          Preferred Method
        </div>

        <big-radio-button value="email" :current-value="newStrategy" :expanded="newStrategy === 'email'" @click="selectStrategy('email')" title="Email" subtitle="Use your email to receive authentication codes" class="bottom-15">
          <div v-if="mfaEnabled && (currentStrategy === 'email' || authenticatableCopy.validOtp)">
            {{ authenticatableCopy.email }}
          </div>

          <email-strategy v-else :on-send-one-time-password="sendOneTimePassword" :verify-otp="verifyOtp" :on-valid-otp="onValidOtp" :authenticatable="authenticatableCopy"></email-strategy>

          <template #notification>
            <inline-notification class="top-20" v-if="validatedStrategy === 'email' && newStrategy === 'email'" type="success">
              Verification successful. Save your changes by clicking the button below.
            </inline-notification>
          </template>
        </big-radio-button>

        <big-radio-button value="authenticator_app" :current-value="newStrategy" :expanded="newStrategy === 'authenticator_app'" @click="selectStrategy('authenticator_app')" title="Authenticator App" subtitle="Use an authenticator app to generate authentication codes" class="bottom-30">
          <div v-if="!firstTime && !allowAuthenticatorAppEdit && authenticatorAppSet" class="top-20">
            <a href="" @click.prevent="changeAuthenticatorApp">Add new app</a>
          </div>

          <authenticator-app-strategy v-else :authenticatable="authenticatableCopy" :verify-otp="verifyOtp" :on-valid-otp="onValidOtp"></authenticator-app-strategy>

          <template #notification>
            <inline-notification class="top-20" v-if="validatedStrategy === 'authenticator_app' && newStrategy === 'authenticator_app'" type="success">
              Verification successful. Save your changes by clicking the button below.
            </inline-notification>
          </template>
        </big-radio-button>
      </div>

      <hr v-if="mfaEnabled || editMode">

      <list-item v-if="allowBackupCodesView" title="Backup Codes" subtitle="Backup codes can be used to sign in if you lose access to your email or authenticator app.">
        <div v-if="editMode">
          <backup-codes-modal ref="backupCodesModal">
            <template #modal-trigger>
              <a href="" @click.prevent="openBackupCodesModal">View/Reset</a>
            </template>
          </backup-codes-modal>
        </div>

        <div v-else>
          <backup-codes-modal ref="backupCodesModal">
            <template #modal-trigger>
              <password-authentication-modal button-text="View/Reset" :password-authentication="passwordAuthentication" :after-authentication-action="openBackupCodesModal" :authenticatable="authenticatable"></password-authentication-modal>
            </template>
          </backup-codes-modal>
        </div>
      </list-item>

      <hr v-if="allowBackupCodesView && editMode">

      <div v-if="editMode">
        <div class="top-20">
          <confirmation-modal ref="confirmationModal" header="Disable Two-Factor Authentication" message="Are you sure you want to disable two-factor authentication? This will remove any settings you previously enabled." yes-btn-text="Disable" no-btn-text="Cancel" :on-confirm-action="save"></confirmation-modal>
          <button type="button" class="nv-button-blue extra-padded a-button-size right-10-not-xs" @click.prevent="disablingMfa ? openConfirmationModal() : save()" :disabled="!canSave">Save Changes</button>

          <button type="button" class="nv-button-white a-button-size top-20-xs" @click.prevent="cancel">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BackupCodesModal from 'vue-app/shared/components/two-factor-authentication/backup-codes-modal.vue';
import EmailStrategy from 'vue-app/shared/components/two-factor-authentication/email-strategy.vue';
import AuthenticatorAppStrategy from 'vue-app/shared/components/two-factor-authentication/authenticator-app-strategy.vue';
import HowItWorksModal from 'vue-app/shared/components/two-factor-authentication/how-it-works-modal.vue';
import MfaInstructions from 'vue-app/shared/components/two-factor-authentication/mfa-instructions.vue';
import PasswordAuthenticationModal from 'vue-app/shared/components/password-authentication-modal.vue';
import ToggleButton from 'vue-app/shared/components/toggle-button.vue';
import ListItem from 'vue-app/shared/components/list-item.vue';
import BigRadioButton from 'vue-app/shared/components/big-radio-button.vue';
import InlineNotification from 'vue-app/shared/components/inline-notification.vue';
import ConfirmationModal from 'vue-app/shared/components/confirmation-modal.vue';
import { isNull, startCase } from 'lodash';

export default {
  name: 'TwoFactorAuthenticationSettings',

  components: {
    BackupCodesModal,
    EmailStrategy,
    AuthenticatorAppStrategy,
    ToggleButton,
    HowItWorksModal,
    MfaInstructions,
    PasswordAuthenticationModal,
    ListItem,
    BigRadioButton,
    InlineNotification,
    ConfirmationModal
  },

  props: {
    authenticatable: {
      type: Object,
      required: true
    },

    sendOneTimePassword: {
      type: Function,
      required: true
    },

    passwordAuthentication: {
      type: Function,
      required: true
    },

    verifyOtp: {
      type: Function,
      required: true
    },

    saveChanges: {
      type: Function,
      required: true
    },

    sectionTitle: {
      type: String,
      default: 'PRIVACY & SECURITY'
    }
  },

  data() {
    return {
      editMode: false,
      authenticatableCopy: {
        otpStrategy: null
      },
      allowAuthenticatorAppEdit: false,
      validatedStrategy: '',
      instructions: {
        title: 'Set up authentication method',
        content: 'By enabling two-factor authentication, you are adding an extra layer of security to your account. Each time you sign in, you will need your password and an authentication code.'
      }
    };
  },

  computed: {
    currentStrategy() {
      return this.authenticatable?.otpStrategy;
    },

    newStrategy() {
      return this.authenticatableCopy.otpStrategy;
    },

    strategyLabel() {
      return startCase(this.currentStrategy);
    },

    mfaEnabled() {
      return (this.editMode ? this.authenticatableCopy : this.authenticatable)?.otpRequiredForLogin;
    },

    mandatoryMfa() {
      return this.authenticatable?.mandatoryMfa;
    },

    disablingMfa() {
      return this.authenticatable.otpRequiredForLogin && !this.authenticatableCopy.otpRequiredForLogin;
    },

    validOtp() {
      return this.authenticatable.validOtp;
    },

    authenticatorAppSet() {
      return this.mfaEnabled && (this.currentStrategy === 'authenticator_app' || (this.currentStrategy !== 'authenticator_app' && this.validOtp));
    },

    canSave() {
      return this.newStrategyValidated || !this.mfaEnabled;
    },

    firstTime() {
      return isNull(this.authenticatable.otpRequiredForLogin);
    },

    twoFactorAdditionalText() {
      if (!this.editMode || !this.mandatoryMfa) { return undefined; }
      return `Two-factor authentication is required by ${this.isAdmin ? 'Priori' : 'your company'} and cannot be disabled.`;
    },

    allowBackupCodesView() {
      return (this.mfaEnabled && this.authenticatable.otpRequiredForLogin) || (!this.authenticatable.otpRequiredForLogin && this.validOtp);
    },

    newStrategyValidated() {
      return this.validOtp && this.mfaEnabled && this.validatedStrategy === this.newStrategy;
    },

    isAdmin() {
      return this.authenticatable.klass === 'AdminUser';
    },

    otpBackupCodeToken() {
      return this.authenticatableCopy?.otpBackupCodeToken;
    }
  },

  methods: {
    selectStrategy(strategy) {
      this.authenticatableCopy.otpStrategy = strategy;
    },

    toggleEditMode(authenticatable) {
      if (!this.editMode && authenticatable) {
        this.authenticatableCopy = Object.assign({}, this.authenticatable, authenticatable);
      }
      else {
        this.authenticatableCopy = {};
      }

      this.validatedStrategy = '';
      this.authenticatable.validOtp = false;
      this.allowAuthenticatorAppEdit = false;
      this.editMode = !this.editMode;
    },

    openBackupCodesModal(authenticatable) {
      this.authenticatableCopy = Object.assign({}, this.authenticatableCopy, authenticatable);
      this.$refs.backupCodesModal.openModal(this.otpBackupCodeToken);
    },

    openConfirmationModal() {
      this.$refs.confirmationModal.openModal();
    },

    toggleMfa() {
      this.authenticatableCopy.otpRequiredForLogin = !this.authenticatableCopy.otpRequiredForLogin;
    },

    changeAuthenticatorApp() {
      this.allowAuthenticatorAppEdit = true;
    },

    onValidOtp() {
      this.allowAuthenticatorAppEdit = false;
      this.validatedStrategy = this.authenticatableCopy.otpStrategy;
    },

    setUpTwoFactor() {
      this.$refs.setUpTwoFactor.openModal();
    },

    firstSetUp(authenticatable) {
      this.toggleEditMode(authenticatable);
      this.authenticatableCopy.otpRequiredForLogin = true;
    },

    save() {
      if (this.newStrategyValidated) {
        this.saveChanges(this.authenticatableCopy, this.toggleEditMode);
      }
      else if (!this.mfaEnabled) {
        this.saveChanges({ otpRequiredForLogin: false }, this.toggleEditMode);
      }
    },

    cancel() {
      this.toggleEditMode();
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/scout/variables";

  hr {
    margin: 10px 0;
  }

  .nv-button-blue, .nv-button-white {
    width: unset;

    &.padded {
      padding: 0;

      @media (min-width: $screen-sm-min) {
        padding: 0 30px;
      }
    };

    &.extra-padded {
      padding: 0 60px;
    };

    &.dark {
      background-color: $alt-dark-blue;
    }
  }

  .asymmetrical {
    @media (min-width: $screen-sm-min) {
      padding: 30px 50px;
    }
  }
</style>
