<template>
  <div>
    <div class="bottom-20">
      During sign-in, you will receive an email from Priori containing an authentication code. You will then enter this code during sign-in to complete the process.
    </div>

    <div class="bold-weight bottom-10">
      To set this up:
    </div>

    <div class="bottom-15">
      1. Click the Send Code button and then check your email.
    </div>

    <div class="row bottom-15">
      <div class="col-sm-7">
        <div class="left-14">
          <div class="bold-weight">
            Email Address
          </div>

          <div class="top-2 text-ellipsis">
            {{ authenticatable.email }}
          </div>
        </div>
      </div>

      <div class="col-sm-5 top-10-xs">
        <div class="bottom-5">
          <button v-if="otpSent || shouldWait" type="button" class="secondary-btn-blue a-button-size" @click.prevent="sendOneTimePassword" :disabled="shouldWait">Resend Code</button>
        </div>

        <div v-if="shouldWait" class="dark-gray-text">
          If you have not received an authentication code, you will be able to request a new one in <span class="bold-weight">{{ countdown }}</span> seconds
        </div>

        <button v-if="!otpSent && !shouldWait" type="button" class="secondary-btn-blue a-button-size" @click.prevent="sendOneTimePassword">Send Code</button>
      </div>
    </div>

    <inline-notification class="bottom-15" v-if="otpSent" type="success">
      Email sent to {{ authenticatable.email }}
    </inline-notification>

    <div class="bottom-15">
      2. Enter the authentication code that appears in your email.
    </div>

    <div>
      <validation-observer v-slot="{ handleSubmit }">
        <form role="form" class="a-form" novalidate>
          <div class="bottom-20">
            <validation-provider rules="required" v-slot="{ errors }" :mode="passiveAggressive">
              <div class="row">
                <div class="col-xs-8 col-sm-4">
                  <input @keydown.enter.prevent="handleSubmit(verify)" type="text" id="email-otp" name="otp" :class="['form-control', { 'has-error': errors.length || invalidOtp }]" maxlength="6" v-model="otpAttempt" autocomplete="off">
                </div>
              </div>

              <div class="error-text top-5" v-if="errors.length">
                * {{ errors[0] }}
              </div>
            </validation-provider>

            <div v-if="invalidOtp" class="error-text top-5">
              * The code you submitted is incorrect. Please input the 6-digit code sent to your email address.
            </div>
          </div>

          <button type="button" class="nv-button-blue a-button-size" @click.prevent="handleSubmit(verify)">Verify and Continue</button>
        </form>
      </validation-observer>
    </div>
  </div>
</template>

<script>
import InlineNotification from 'vue-app/shared/components/inline-notification.vue';
import interactionModes from 'vue-app/shared/mixins/interaction-modes';
import { ValidationObserver, ValidationProvider } from 'vee-validate';

export default {
  name: 'EmailStrategy',

  components: {
    ValidationObserver,
    ValidationProvider,
    InlineNotification
  },

  mixins: [
    interactionModes
  ],

  props: {
    authenticatable: {
      type: Object,
      required: true
    },

    onSendOneTimePassword: {
      type: Function,
      required: true
    },

    verifyOtp: {
      type: Function,
      required: true
    },

    onValidOtp: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      otpAttempt: '',
      otpSent: false,
      invalidOtp: false,
      countdownStart: 60,
      countdown: 60,
      shouldWait: false
    };
  },

  computed: {
    waitTime() {
      return Math.floor(Date.now() / 1000) - this.authenticatable.lastOtpSentAt;
    }
  },

  mounted() {
    if (this.waitTime < 60) {
      this.shouldWait = true;
      this.otpSent = true;
      this.startCountdown(true);
    }
  },

  methods: {
    sendOneTimePassword() {
      if (!this.shouldWait) {
        this.shouldWait = true;
        this.onSendOneTimePassword()
        .then(
          () => {
            this.otpSent = true;
            this.startCountdown();
          }
        )
        .catch(
          (response) => {
            this.authenticatable.lastOtpSentAt = response.data.lastOtpSentAt;
            this.startCountdown(true);
          }
        );
      }
    },

    startCountdown(waiting) {
      if (waiting) {
        this.countdown = 60 - this.waitTime;
      }

      let interval = setInterval(() => {
        this.countdown--;

        if (this.countdown < 1) {
          this.countdown = this.countdownStart;
          this.shouldWait = false;

          clearInterval(interval);
        }
      }, 1000);
    },

    verify() {
      this.invalidOtp = false;

      this.verifyOtp(this.otpAttempt, 'email')
      .then(
        this.onValidOtp
      )
      .catch(
        () => { this.invalidOtp = true; }
      );
    }
  }
};
</script>

<style lang="scss" scoped>
  .secondary-btn-blue, .nv-button-blue {
    width: unset;
    padding: 0 10px;

    @media (min-width: 768px) {
      padding: 0 20px;
    }
  }

  .left-14 {
    @media (min-width: 768px) {
      margin-left: 14px;
    }
  }
</style>
