<template>
  <div :class="['big-radio-button', { 'selected': isChecked || !currentValue }]" @click="$emit('click', $event.target.value)">
    <div class="button-face">
      <input type="radio" :checked="isChecked" value="value">

      <div>
        <div class="semibold-weight size-text-16px">
          {{ title }}
        </div>

        <div v-if="hasSubtitle" class="dark-gray-text top-2">
          {{ subtitle }}
        </div>
      </div>
    </div>

    <div class="expanded-content top-20" v-if="expanded || isChecked">
      <slot></slot>
    </div>

    <div class="notification">
      <slot name="notification"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BigRadioButton',

  props: {
    value: {
      type: String,
      default: undefined
    },

    currentValue: {
      type: String,
      default: undefined
    },

    title: {
      type: String,
      required: true
    },

    subtitle: {
      type: String,
      default: undefined
    },

    expanded: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    isChecked() {
      return this.currentValue === this.value;
    },

    hasSubtitle() {
      return this.subtitle && this.subtitle.length;
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/scout/variables";

  .big-radio-button {
    background: $grey-1;
    border: 1px solid $grey-4;
    border-radius: $border-radius-large;
    padding: 20px;
    line-height: 1.4;

    &:hover {
      border-color: rgba($grey-4, .6);
    }

    &.selected {
      background: $white;
    }
  }

  .button-face {
    display: flex;
    align-items: center;
    column-gap: 20px;
    cursor: pointer;
  }

  .expanded-content {
    @media (min-width: $screen-sm-min) {
      padding-left: 33px;
    }
  }
</style>
