<template>
  <div class="rounded-frame">
    <div class="options text-right-not-xs">
      <span class="right-15">
        <copy-to-clipboard :text-to-copy="backupCodes.join(', ')" tooltip-text="Codes copied to clipboard">
          <svg-icon name="copy" class="base-icon right-3"></svg-icon>Copy Codes
        </copy-to-clipboard>
      </span>

      <span>
        <a href="" @click.prevent="saveFile"><svg-icon name="download" class="base-icon right-2"></svg-icon>Download</a>
      </span>
    </div>

    <div class="list-spacing-10" v-for="code in backupCodes" :key="code">
      <code>{{ code }}</code>
    </div>
  </div>
</template>

<script>
import CopyToClipboard from 'vue-app/shared/components/copy-to-clipboard.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';

export default {
  name: 'BackupCodes',

  components: {
    CopyToClipboard,
    SvgIcon
  },

  props: {
    backupCodes: {
      type: Array,
      required: true
    }
  },

  methods: {
    saveFile() {
      let FileSaver = require('file-saver');
      let blob = new Blob([this.backupCodes.join('\r\n')], { type: 'text/plain;charset=utf-8' });
      FileSaver.saveAs(blob, 'Backup Codes.txt');
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/scout/variables";

  .rounded-frame {
    border: 1px solid $grey-3;
    border-radius: $border-radius-large;
    padding: 5px 20px 20px;

    @media (min-width: $screen-sm-min) {
      padding: 5px 30px 20px;
    }
  }

  .base-icon {
    fill: $k-blue;
    height: 13px;

    &.right-3 {
      margin-right: 3px;
    }

    &.right-2 {
      margin-right: 2px;
    }
  }

  .options {
    margin-bottom: 15px;
    font-size: 12px;

    @media (min-width: $screen-sm-min) {
      position: relative;
      top: 20px;
      margin-bottom: 0;
    }
  }
</style>
