<template>
  <validation-provider :rules="validationRules" v-slot="{ errors }" :mode="passiveAggressive">
    <div class="row">
      <div class="col-sm-4">
        <label :for="id" :class="{ 'no-margin': !editMode }">{{ label }}<span v-if="required" class="inline-help required"> *</span></label>

        <div class="error-text top-5" v-if="editMode && errors.length && showErrorMessage">
          {{ errors[0] }}
        </div>
      </div>

      <div class="col-sm-8">
        <div v-if="!editMode">
          {{ currentValue || '&mdash;' }}
        </div>

        <div class="top-10-xs" v-if="editMode">
          <input type="text" :data-testid="id" :id="id" :name="name" :class="['form-control', { 'has-error': errors.length }]" @input="onInput($event.target.value)" :value="$attrs.value">
        </div>
      </div>
    </div>
  </validation-provider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';
import interactionModes from 'vue-app/shared/mixins/interaction-modes';

export default {
  name: 'TextInputHorizontal',

  components: {
    ValidationProvider
  },

  mixins: [
    interactionModes
  ],

  props: {
    id: {
      type: String,
      required: false
    },

    label: {
      type: String,
      required: true
    },

    name: {
      type: String,
      required: false
    },

    currentValue: {
      type: String,
      default: ''
    },

    editMode: {
      type: Boolean,
      default: false
    },

    novalidate: {
      type: Boolean,
      default: false
    },

    showErrorMessage: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    validationRules() {
      return this.novalidate ? null : 'required';
    },

    required() {
      return (this.validationRules || '').includes('required');
    }
  },

  methods: {
    onInput(value) {
      this.$emit('input', value);
    }
  }
};
</script>
