<template>
  <div class="instructions">
    <div>
      <div class="size-text-20px bottom-5">
        {{ title }}
      </div>

      <div class="size-text-14px dark-gray-text">
        {{ content }}
      </div>
    </div>

    <div>
      <svg-icon name="devices" class="devices-image"></svg-icon>
    </div>
  </div>
</template>

<script>
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';

export default {
  name: 'MfaInstructions',

  components: {
    SvgIcon
  },

  props: {
    title: {
      type: String,
      required: true
    },

    content: {
      type: String,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
  .devices-image {
    width: 90px;
    height: 68px;
    margin-top: 20px;

    @media (min-width: 768px) {
      margin-top: 0;
    }
  }

  .instructions {
    @media (min-width: 768px) {
      display: flex;
      column-gap: 50px;
      justify-content: space-between;
      padding-right: 14px;
      align-items: center;
    }
  }
</style>
