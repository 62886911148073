<template>
  <div>
    <div class="bottom-20">
      During sign-in, you will use an authenticator app to generate an authentication code. You will then enter this code during sign-in to complete the process.
    </div>

    <div class="bold-weight bottom-10">
      To set this up:
    </div>

    <div class="bottom-10">
      <div>
        1. Open your authenticator app.
      </div>

      <div class="top-2 left-14">
        <compatible-apps-modal ref="compatibleAppsModal"></compatible-apps-modal>
      </div>
    </div>

    <div class="bottom-15">
      2. Add an account within the app and scan the following QR code.
    </div>

    <div class="qr bottom-15">
      <div class="text-center">
        <qrcode-vue :value="authenticatable.otpProvisioningUri" size="100"></qrcode-vue>
      </div>

      <div>
        <div class="bottom-10 dark-gray-text">
          If you cannot scan, you can try entering the following code manually:
        </div>

        <div class="bold-weight secret">
          {{ authenticatable.otpSecretKey }}
        </div>
      </div>
    </div>

    <div>
      <div class="bottom-15">
        3. Enter the six-digit authentication code that appears after you have scanned the QR code.
      </div>

      <validation-observer v-slot="{ handleSubmit }">
        <form role="form" class="a-form" novalidate>
          <div class="bottom-20">
            <validation-provider rules="required" v-slot="{ errors }" :mode="passiveAggressive">
              <div class="row">
                <div class="col-xs-8 col-sm-4">
                  <input @keydown.enter.prevent="handleSubmit(verify)" type="text" name="otp" :class="['form-control', { 'has-error': errors.length || invalidOtp }]" maxlength="6" v-model="otpAttempt" autocomplete="off">
                </div>
              </div>

              <div class="error-text top-5" v-if="errors.length">
                {{ errors[0] }}
              </div>
            </validation-provider>

            <div v-if="invalidOtp" class="error-text top-5">
              * The code you submitted is incorrect. Please check your authenticator app to find the correct 6-digit code.
            </div>
          </div>

          <button type="button" class="nv-button-blue a-button-size" @click.prevent="handleSubmit(verify)">Verify and Continue</button>
        </form>
      </validation-observer>
    </div>
  </div>
</template>

<script>
import CompatibleAppsModal from 'vue-app/shared/components/two-factor-authentication/compatible-apps-modal.vue';
import QrcodeVue from 'qrcode.vue';
import interactionModes from 'vue-app/shared/mixins/interaction-modes';
import { ValidationObserver, ValidationProvider } from 'vee-validate';

export default {
  name: 'AuthenticatorAppStrategy',

  components: {
    QrcodeVue,
    ValidationObserver,
    ValidationProvider,
    CompatibleAppsModal
  },

  mixins: [
    interactionModes
  ],

  props: {
    authenticatable: {
      type: Object,
      required: true
    },

    verifyOtp: {
      type: Function,
      required: true
    },

    onValidOtp: {
      type: Function,
      default: () => {}
    }
  },

  data() {
    return {
      otpAttempt: '',
      invalidOtp: false
    };
  },

  methods: {
    openCompatibleAppsModal() {
      this.$refs.compatibleAppsModal.openModal();
    },

    verify() {
      this.invalidOtp = false;

      this.verifyOtp(this.otpAttempt, 'authenticator_app')
      .then(
        this.onValidOtp
      )
      .catch(() => {
        this.invalidOtp = true;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/scout/variables";

  .qr {
    @media (min-width: $screen-sm-min) {
      display: flex;
      align-items: center;
      column-gap: 15px;
      padding: 0 15px;
    }

    @media (min-width: $screen-lg-min) {
      column-gap: 35px;
      padding: 0 35px;
    }
  }

  .secret {
    background: $k-light-gray;
    border-radius: $border-radius-base;
    padding: 5px;
    text-align: center;
    letter-spacing: 1px;
    font-size: 12px;
    word-break: break-all;
  }

  .nv-button-blue {
    width: unset;
    padding: 0 10px;

    @media (min-width: $screen-sm-min) {
      padding: 0 20px;
    }
  }

  .left-14 {
    margin-left: 14px;
  }
</style>
