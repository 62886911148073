<template>
  <priori-modal ref="modal" title="Backup Codes" modal-size="md" modal-id="backup-codes-modal" @on-modal-close="onModalClose">
    <template #modal-trigger>
      <slot name="modal-trigger"></slot>
    </template>

    <div class="bottom-20">
      Below is a list of your backup codes. Please store these codes in a safe place. They can be used to sign in if you lose access to your email or authenticator app. Each code may only be used once. You will need to generate new backup codes once you have used up all existing ones.
    </div>

    <inline-notification v-if="backupCodesRegenerated" type="success" class="bottom-10">
      New Codes Generated
    </inline-notification>

    <list-item title="Your Backup Codes">
      <a href="" class="size-text-12px" @click.prevent="regenerate">Generate New Codes</a>
    </list-item>

    <loading-section name="backupCodes">
      <backup-codes :backup-codes="backupCodes"></backup-codes>
    </loading-section>
  </priori-modal>
</template>

<script>
import BackupCodes from 'vue-app/shared/components/two-factor-authentication/backup-codes.vue';
import PrioriModal from 'vue-app/shared/components/priori-modal.vue';
import InlineNotification from 'vue-app/shared/components/inline-notification.vue';
import ListItem from 'vue-app/shared/components/list-item.vue';
import LoadingSection from 'vue-app/shared/components/loading-section.vue';
import TwoFactorAuthenticatable from 'resources/two-factor-authenticatable.js';
import LoadingService from 'vue-app/shared/services/loading-service';

export default {
  name: 'BackupCodesModal',

  components: {
    BackupCodes,
    PrioriModal,
    InlineNotification,
    ListItem,
    LoadingSection
  },

  data() {
    return {
      backupCodesRegenerated: false,
      backupCodes: [],
      otpBackupCodeToken: ''
    };
  },

  methods: {
    openModal(otpBackupCodeToken) {
      this.$refs.modal.openModal();
      this.otpBackupCodeToken = otpBackupCodeToken;
      this.loadOtpBackupCodes();
    },

    regenerate() {
      const vueInstance = this;

      TwoFactorAuthenticatable.regenerateBackupCodes().then(function () {
        vueInstance.backupCodesRegenerated = true;
        vueInstance.loadOtpBackupCodes();
      }, function () {});
    },

    onModalClose() {
      this.backupCodesRegenerated = false;
      this.otpBackupCodeToken = '';
    },

    loadOtpBackupCodes() {
      const vueInstance = this;

      LoadingService.loading('backupCodes');

      TwoFactorAuthenticatable.otpBackupCodes({ otpBackupCodeToken: this.otpBackupCodeToken })
        .then(function (response) {
          vueInstance.backupCodes = response.data;

          LoadingService.done('backupCodes');
        }, function () {
          LoadingService.done('backupCodes');
        });
    }
  }
};
</script>
